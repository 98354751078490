import { OpenIDConnectScheme, } from '@nuxtjs/auth-next';
export default class OpenIDConnectClaimsScheme extends OpenIDConnectScheme {
    constructor($auth, options, ...defaults) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { CONFIG } = require('@shared/environment/environment');
        const { apiUrl, keycloakUrl, keycloakClientId, keycloakConfigPath } = CONFIG;
        const oidcTokenPath = `/oauth/jwt/get`;
        const oidcUserPath = `/auth/me`;
        super($auth, {
            name: options.name,
            responseType: 'code',
            grantType: 'authorization_code',
            clientId: keycloakClientId,
            token: {
                property: 'access',
                name: 'API-Authorization',
            },
            idToken: {
                property: 'id_token',
            },
            user: {
                property: false,
            },
            endpoints: {
                token: new URL(oidcTokenPath, apiUrl).href,
                userInfo: new URL(oidcUserPath, apiUrl).href,
                configuration: new URL(keycloakConfigPath, keycloakUrl).href,
            },
        }, ...defaults);
    }
    async fetchUser() {
        if (!this.check(false).valid) {
            return;
        }
        const { data } = await this.$auth.requestWith(this.name, {
            url: this.options.endpoints.userInfo,
        });
        this.$auth.setUser(data);
        await this.$auth.ctx.store.dispatch('settings/fetchMenuEntries', undefined, { root: true });
    }
}
